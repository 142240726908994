@import url("https://fonts.googleapis.com/css?family=Rubik+Glitch");
@import url("https://fonts.googleapis.com/css?family=VT323");
@import url("https://fonts.googleapis.com/css?family=Chakra+Petch");



.App {
  text-align: center;
  font-family: "Rubik Glitch", "VT232","Chakra Petch";
  color: white;
}

* {
box-sizing: border-box;
}

body {

  overflow: hidden;
}

.overlay {
  opacity: 0.9;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/background/cathideout.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}
